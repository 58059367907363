<template>
  <div class="PushHistory">
    <div class="search-product pr-4 pl-4">
      <v-text-field :label="$t('app.search')" v-model="keyword" dark>
        <template v-slot:prepend>
          <v-btn
            :disabled="keyword.length < 2"
            tile
            icon
            dark
            class="ma-0"
            @click="getList(keyword)"
          >
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <div v-if="noResult">
      <v-card class="mr-4 ml-4">
        <v-card-text>Nincs elérhető elem</v-card-text>
      </v-card>
    </div>
    <v-card
      tile
      class="d-flex card mb-4 mr-4 ml-4 pa-2"
      v-for="item in data"
      :key="item.id"
    >
      <figure class="mr-4">
        <v-img width="60" src="../assets/svg/hell_piros.svg"></v-img>
      </figure>
      <div class="meta">
        <small>{{ formatDate(item.createdAt) }}</small>
        <h4>{{ item.title }}</h4>
        <p>{{ item.message }}</p>
      </div>
      <div class="date"></div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PushHistory",
  data() {
    return {
      data: [],
      keyword: "",
      noResult: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(keyword) {
      if (!keyword) keyword = "";
      this.$store
        .dispatch("getCollection", {
          end: `client/push-notifications/filtered?keyword=${keyword}`,
          payload: null,
        })
        .then((res) => {
          this.data = res;
          this.noResult = this.data.length > 0 ? false : true;
        });
    },
    formatDate(d) {
      return moment.unix(d).format("YYYY.MM.DD, H:mm");
    },
  },
};
</script>
<style scoped>
.card {
  border-bottom: 3px solid var(--red);
}
</style>
